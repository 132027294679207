import { useEffect, useMemo, useState } from 'react';
import PouchDB from 'pouchdb-browser';
import pouchdbFind from 'pouchdb-find';
import pouchdbAuthentication from 'pouchdb-authentication';

PouchDB.plugin(pouchdbAuthentication);
PouchDB.plugin(pouchdbFind);
PouchDB.plugin(require('pouchdb-upsert'));

const couchdb_name = localStorage.getItem('couchdb_name');
const couchdb_user = localStorage.getItem('couchdb_user');
const couchdb_password = localStorage.getItem('couchdb_password');

const remoteUrl = `${process.env.REACT_APP_COUCHDB_URL}/${couchdb_name}`;

export const usePouchDB = () => {
    const [error, setError] = useState(false);
    const [ready, setReady] = useState(false);
    const [alive, setAlive] = useState(true);

    let pouchDBLocal = new PouchDB(`${couchdb_name}`);

    let pouchDBRemote = new PouchDB(remoteUrl, {skip_setup: true});

    let loginOpts = {
        ajax: {
            headers: {
                Authorization: 'Basic ' + window.btoa(couchdb_user + ':' + couchdb_password)
            }
        }
    };

    pouchDBRemote.logIn(
        couchdb_user, couchdb_password, loginOpts
    ).then(function () {
        console.log("Connected!");
        //return pouchDBRemote.logOut();
    });

    // Hàm đăng nhập CouchDB
    const login = async () => {
        try {
            await pouchDBRemote.logIn(

                couchdb_user, couchdb_password, loginOpts
            ).then(function () {
                console.log("Connected!");
            });
            console.log("Reconnected to CouchDB");
        } catch (error) {
            console.error("Re-login failed:", error);
            setError(true);
        }
    };

    // Kiểm tra và giữ phiên hoạt động
    useEffect(() => {
        const intervalId = setInterval(async () => {
            try {
                await pouchDBRemote.info(); // Kiểm tra xem phiên có còn hiệu lực không
                setAlive(true);
            } catch (error) {
                console.log("Session expired, attempting to re-login...");
                setAlive(false);
                await login(); // Đăng nhập lại nếu phiên hết hạn
            }
        }, 600000); // Mỗi 10 phút (600000 ms)

        return () => clearInterval(intervalId);
    }, [pouchDBRemote]);

    // Create the local and remote databases for syncing
    const [localDb, remoteDb] = useMemo(() => [pouchDBLocal, pouchDBRemote], []);

    // Start the sync in a separate effect, track any critical errors.
    useEffect(() => {
        const canceller = localDb
            .sync(remoteDb, {
                live: true,
                heartbeat: false,
                timeout: false,
                retry: true,
            })
            .on('error', () => {
                setAlive(false);
                setError(true);
            });

        return () => {
            canceller.cancel();
        };
    }, [localDb, remoteDb]);

    // Create an interval after checking the status of the database for the
    // first time
    useEffect(() => {
        let cancelInterval;

        remoteDb
            .info()
            .then(() => {
                setReady(true);

                cancelInterval = setInterval(() => {
                    remoteDb
                        .info()
                        .then(() => {
                            setAlive(true);
                        })
                        .catch(() => {
                            setAlive(false);
                        });
                }, 15000)
            })
            .catch(() => {
                setError(true);
            });

        return () => {
            clearTimeout(cancelInterval);
        };
    }, [remoteDb]);

    return { db: localDb, ready, alive, error };
};
